import React, { useState, forwardRef, useImperativeHandle } from "react";

// @material-ui core components
import { Alert, Snackbar } from "@mui/material";

const CustomSnackbarAlerts = forwardRef((props, ref) => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarAlertColor, setSnackbarAlertColor] = useState("info");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  // create our ref object
  const publicRef = {
    errorAlert: (error) => {
      let errorMessage = error;
      if (error.response && error.response.data) errorMessage = error.response.data.message;
      else if (error.message) errorMessage = error.message;
      else if (typeof errorMessage !== "string") {
        errorMessage = "Error!";
        console.error(error);
      }

      if (errorMessage === "jwt expired") {
        errorMessage += ". Please refresh the page and try again.";
      }
      setSnackbarAlertColor("error");
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
    },
    errorAlertForBufferResponse: async (error) => {
      let errorMessage = "Error!";
      try {
        // Check if error.response.data exists
        if (error.response && error.response.data) {
          // Determine if response data is an ArrayBuffer or Blob
          if (error.response.data instanceof Blob) {
            // Use Blob's .text() method to read content as text
            const text = await error.response.data.text();
            const parsedError = JSON.parse(text);
            errorMessage = parsedError.message || "Error: Failed to download the file!";
          } else if (error.response.data instanceof ArrayBuffer) {
            // Use TextDecoder for ArrayBuffer
            const decoder = new TextDecoder("utf-8");
            const text = decoder.decode(new Uint8Array(error.response.data));
            const parsedError = JSON.parse(text);
            errorMessage = parsedError.message || "Error: Failed to download the file!";
          }
        } else if (error.message) {
          errorMessage = error.message;
        }
      } catch (parseError) {
        console.error("Error parsing the response data:", parseError);
        errorMessage = "Error: Could not parse the error response.";
      }
      // Additional handling for specific error messages
      if (errorMessage === "jwt expired") {
        errorMessage += ". Please refresh the page and try again.";
      }
      // Set error message and open snackbar alert
      setSnackbarAlertColor("error");
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
    },
    customAlert: (message, severity = "info") => {
      if (message === "jwt expired") {
        message += ". Please refresh the page and try again.";
      }
      setSnackbarAlertColor(severity);
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    },
    alertClose: () => {
      setSnackbarOpen(false);
    },
  };
  // pass the ref and a function that returns our object
  useImperativeHandle(ref, () => publicRef);
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={snackbarOpen}
      autoHideDuration={7000}
      onClose={handleSnackbarClose}
    >
      <Alert
        onClose={handleSnackbarClose}
        sx={{ width: "100%" }}
        variant="filled"
        severity={snackbarAlertColor}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
});

CustomSnackbarAlerts.displayName = "CustomSnackbarAlerts";

export default CustomSnackbarAlerts;
